.container-body-1 {
  background: rgb(252, 247, 247);
  background: radial-gradient(#e7dbe18a, #77eca8);
}

.container-body-2 {
  background: rgb(185, 185, 185);
  background: radial-gradient(#f1e8e8, #d3ebce);
  align-items: center;
}

.container-body-3 {
  background: rgb(255, 255, 255);
  background: radial-gradient(#f6faf5, #dee6c8);
}

.cardsContent-1 {
  background: radial-gradient(#fffdfe, #77eca8);
}

.cardsContent-2 {
  background: radial-gradient(#fbc1cc, #fa99b2);
}

.cardsContent-3 {
  background: radial-gradient(#76b2fe, #b69efe);
}

.cardsContent-4 {
  background: radial-gradient(#60efbc, #58d5c9);
}

.card-counter {
  box-shadow: 2px 2px 10px #000000;
  background: radial-gradient(#ebe7e8, #a9eb8f);
  margin: 5px;
  padding: 15px 10px;
  height: 100px;
  border-radius: 5px;
  transition: 0.3s linear all;
}

.trusted-by {
  width: auto;
  /* 284px */
  height: auto;
  /* 70px */
  white-space: pre;
  overflow: visible;
  font-weight: 700;
  font-style: normal;
  font-family: 'Inter', sans-serif;
  color: var(--token-b9c3c957-2c66-49c4-9732-fa196fddac20, #001122);
  font-size: 58px;
  letter-spacing: -2.1px;
  line-height: 1.2;
  text-align: center;
  position: absolute;
}

.logos {
  box-sizing: border-box;
  flex-shrink: 0;
  width: 100%;
  height: 376px;
  display: block;
  box-shadow: 7px 8px 2px 0px #7755cc, 0px 2px 4px 0px #999999,
    20px 4px 8px 0px rgba(0, 0, 0, 0.25);
  background-color: #f2e3e3;
  overflow: visible;
  position: relative;
  border-radius: 61px;
  border: 5px solid #222;
}
