.filter {
    border-top-right-radius: 10px;
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
}

.profiles img {
    margin-right: -6px;
}

.col-md-4 {
    margin-top: 26px;
}