 /* .bg {
     -webkit-mask-image: linear-gradient(black, transparent);
     mask-image: linear-gradient(rgb(255, 255, 255), transparent);
 } */

 .nav-logo {
     width: 150px;
 }

 .bi {
     font-size: 25px;
     color: azure;

 }